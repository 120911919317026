export default [{
  path: '/v2/dashboards/atendimentos',
  name: 'route_dashboards_atendimentos',
  component: () => import('@/modules/dashboards/views/DashboardView.vue')
}, {
  path: '/v2/dashboards/two',
  name: 'dashtwo',
  component: () => import('@/modules/dashboards/views/DashTwoView.vue')
}, {
  path: '/v2/dashboards/*',
  redirect: { path: '/v2/dashboards/atendimentos' }
}]
